import React, { useState } from 'react'
import '../styles/cabinet.scss'
import { translatedText } from '../services/translatedText'
import Proms from '../components/Proms'
import { withTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { getCurrentTariff, getTariffs } from '../services/http/tariffs'
import { getUserPhones, getUserServices } from '../services/http/userAPI'
import { useNavigate } from 'react-router-dom'
import useActions from '../hooks/useActions'
import UserInfo from '../components/UserInfo'
import TariffCard from '../components/TariffCard'
import { splitTariffName } from '../utils/parser'
import SentAboutErrorModal from '../components/SentAboutErrorModal'
import AdditionalServices from '../components/AdditionalServices'
import BannerHome from '../components/Banner'

export default withTranslation()(function Cabinet() {
  const [actions, { user, tariff: globalTariff, contacts, services: globalServices }] = useActions()

  const [tariff, setTariff] = useState()
  const [services, setServices] = useState([])
  const [phones, setPhones] = useState()
  const [openModal, setOpenModal] = useState({ open: false })
  const [reload,setReload] = useState(false)

  async function fetchData() {
    try {
      if (!globalTariff?.id) {
        const tempCurrentTariff = (await getCurrentTariff())[0]
        actions.setTariff(tempCurrentTariff)
        setTariff(tempCurrentTariff)
      } else if (!tariff) {
        setTariff(globalTariff)
      }
      const servs = Array.isArray(globalServices) ? globalServices : null
      const service = servs || (await getUserServices())
      if (!servs) actions.setServices(service)

      const services = await getUserServices()

      if (user?.credit ? parseInt(user.credit) : 0) services.push({ id: 'postponement-1', activeService: 1 })
      setServices(services.filter((serv) => serv?.activeService == 1))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      let phone = contacts || []
      actions.setContacts(phone)
      setPhones(Array.isArray(phone) ? phone.filter((ph) => ph.name.includes('PHONE')) : [])
    }
    fetchData()
  }, [contacts])

  useEffect(() => {
    if (user?.login) fetchData()
  }, [user,reload])

  useEffect(() => {
    fetchData()
  }, [])

  const history = useNavigate({
    disrespectUserMotionPreference: true,
  })

  const handleCloseModal = () => {
    setOpenModal({ open: false })
  }

  const handleModal = () => {
    setOpenModal({ open: true })
  }

  return (
    <div className="cabinet">
        <div style={{ background: "#FAFAFA" }}>
        <div className="container">
          <BannerHome />
        </div>
      </div>
      <div className="cabinet-body">
        <SentAboutErrorModal setReload={setReload} openModal={openModal} handleCloseModal={handleCloseModal} currentData={{ phones, user }} />
        <div style={{ position: 'relative' }} className="container">
          {/* <TerminateContract pi={pi} /> */}
          {user && tariff ? <UserInfo reportBug={handleModal} user={user} tariff={tariff} /> : null}

          <h1 style={{ margin: '30px 0 0 0' }}>{translatedText('Your Tariff')}</h1>

          {!tariff?.tpName && tariff !== undefined ? (
            <p className="error-text1">{translatedText('Tariff not exists')}</p>
          ) : (
            <TariffCard current history={history} tariff={tariff} user={user} />
          )}

          <p className="unfocus-text2 t-center">
            <span>* {translatedText('Speed-alert')}</span>
          </p>
          <AdditionalServices services={services} />

          <Proms />
        </div>
      </div>
    </div>
  )
})
