import React from "react";
import i18next from "i18next";
import { useLocation, useParams } from "react-router-dom";
import "../styles/single-prom.css";
import { ComnetTitle } from "../components/ComnetTitle";
import Share from "../components/Share";

const SingleProm = () => {
  const lang = i18next.language;
  const { state } = useLocation();
  const { pid } = useParams();

  const [currentProm, setCurrentProm] = React.useState({});

  React.useEffect(() => {
    if (!pid || !state?.proms) return;
    setCurrentProm(state.proms.find((item) => item.id === +pid));
  }, [state, pid]);

  return (
    <div>
      {currentProm !== {} && (
        <div className="SingleNews">
          <div className="SingleNews__inner">
            <div className="SingleNews__items">
              <div className="SingleNews__left">
                <h1 className="SingleNews__headTitle">
                  {lang === "ru" ? currentProm.title_ru : currentProm.title_uz}
                </h1>
                <div className="SingleNews__orderWr">
                  <article
                    className="SingleNews__orderTitle"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "ru"
                          ? currentProm.short_text_ru
                          : currentProm.short_text_uz,
                    }}
                  />
                </div>
                <ComnetTitle />
              </div>
              <div className="SingleNews__right">
                <Share pic={currentProm.pic} />
              </div>
            </div>
            {/* <News /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleProm;
